.gallery {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
	grid-gap: 25px;
	gap: 25px;

	@include min(800px) {
		grid-gap: 35px;
		gap: 35px;
	}

	@include min(1024px) {
		grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
		grid-gap: 45px;
		gap: 45px;
	}

	@include min(1200px) {
		grid-gap: 60px;
		gap: 60px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__link {
		line-height: 0;
		display: block;
		position: relative;

		&::after  {
			content: "";
			@include fill;
			background: rgba(#0d2433, 0.4)
				url("/assets/images/magnifying-glass.svg") no-repeat center center;
			opacity: 0;
			box-shadow: 0 0 0 0 white inset;
			transition: opacity 0.2s ease-out, box-shadow 0.2s ease-out;
			mix-blend-mode: hard-light;
		}

		@include link(&) {
		}

		@include link-over(&) {
			&::after  {
				box-shadow: 0 0 0 6px white inset;
				opacity: 1;
			}
		}
	}

	&__image {
		width: 100%;
	}
}
