@charset "UTF-8";
/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
.keyboard-focus:focus {
  outline: 3px skyblue solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
::selection {
  background: #00569d;
  color: #f6f2ef;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: underline;
}

p a:visited {
  opacity: 0.8;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Titillium Web", sans-serif;
}

h1:not(:first-child), h2:not(:first-child), h3:not(:first-child), h4:not(:first-child), h5:not(:first-child), h6:not(:first-child) {
  margin-top: 0;
}

h1, h2, h3, h4 {
  font-weight: 700;
  line-height: 1.15;
  margin: 0 0 0.75em 0;
}

h5, h6 {
  font-weight: 600;
  margin: 0 0 0.5em 0;
}

.main-heading {
  font-size: 2.125rem;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .main-heading {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .main-heading {
    font-size: 2.875rem;
  }
}

h2 {
  font-size: 2.75rem;
}

@media screen and (min-width: 800px) {
  h2 {
    font-size: 3.125rem;
  }
}

@media screen and (min-width: 1280px) {
  h2 {
    font-size: 3.75rem;
  }
}

h3 {
  font-size: 1.875rem;
  font-weight: normal;
}

h4 {
  font-size: 1.625rem;
}

h5 {
  font-size: 1.375rem;
}

h6 {
  font-size: 1.125rem;
}

:focus {
  outline: 0;
}

blockquote {
  padding: 4vw;
  margin: 5vw;
  border-left: 1em #00569d solid;
  font-size: 1.6em;
  font-style: normal;
  /* cite {
		font-style: normal;
	} */
}

blockquote p {
  margin: 0;
  position: relative;
}

@media screen and (min-width: 800px) {
  blockquote {
    margin: 4vw;
  }
}

@media screen and (min-width: 1200px) {
  blockquote {
    margin: 3em 5em;
    padding-left: 4em;
    padding-right: 4em;
  }
}

code,
pre {
  font-size: 1em;
  font-family: SFMono-Regular, "Roboto Mono", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

.vh {
  position: absolute;
  left: -9999rem;
  width: 0;
  height: 0;
}

/*
* COMMON BLOCKS
*/
.header {
  background: white;
  color: #7a7a7a;
  position: sticky;
  top: 0;
  z-index: 100;
}

@media screen and (min-width: 800px) {
  .header {
    position: relative;
  }
}

.header__content  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header__logo {
  margin: 18px 0;
  line-height: 0;
}

@media screen and (max-width: 799px) {
  .header__logo {
    position: relative;
    z-index: 220;
  }
}

@media screen and (min-width: 800px) {
  .header__logo {
    margin: 28px 0;
  }
}

.header__logo-image {
  width: 200px;
}

@media screen and (min-width: 800px) {
  .header__logo-image {
    width: 300px;
  }
}

.header__menu-button {
  position: absolute;
  top: 50%;
  right: calc(5% - 15px);
  z-index: 300;
  transform: translateY(calc(-50% - 0.15em));
}

@media screen and (min-width: 800px) {
  .header__menu-button {
    display: none;
  }
}

.header__tools {
  font-size: 0.875rem;
}

@media screen and (max-width: 799px) {
  .header__tools {
    position: fixed;
    z-index: 205;
    left: 0;
    top: 5rem;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    padding: 0 5%;
  }
  .menu-is-open .header__tools {
    transform: translateX(0);
  }
}

.header__tools-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

@media screen and (max-width: 799px) {
  .header__tools-list {
    justify-content: center;
  }
}

.header__tools-item:not(:first-child) {
  margin-left: 50px;
}

.header .header__tools-link,
.header .header__tools-link:link,
.header .header__tools-link:visited {
  text-decoration: none;
  color: #6d6d6d;
}

.header .header__tools-link svg,
.header .header__tools-link:link svg,
.header .header__tools-link:visited svg {
  fill: currentColor;
}

.header .header__tools-link:hover,
.header .header__tools-link:focus,
.header .header__tools-link:active {
  color: #00569d;
}

.header__tools-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.header__tools-link svg {
  fill: currentColor;
}

.header__tools-icon {
  margin-left: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flair {
  height: 0;
  padding-top: 120px;
  background: #112c3e url(/assets/images/footer-bg.jpg) no-repeat center center/cover;
}

.footer {
  text-align: left;
  margin-top: auto;
  color: #638094;
  background: #112c3e url(/assets/images/pattern-dark.svg) repeat-x left -6px bottom 6px;
  padding: 50px 0;
}

@media screen and (min-width: 800px) {
  .footer {
    padding: 80px 0;
  }
}

@media screen and (min-width: 1280px) {
  .footer {
    padding: 120px 0;
  }
}

.footer__content {
  text-align: center;
  display: grid;
  margin-bottom: 42px;
  font-size: 0.875rem;
  line-height: 1.64286;
  grid-gap: 40px;
  gap: 40px;
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  .footer__content {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "copyright copyright" "address contact" "social social";
  }
  .footer__content .footer__column--copyright {
    grid-area: copyright;
  }
  .footer__content .footer__column--address {
    grid-area: address;
  }
  .footer__content .footer__column--contact {
    grid-area: contact;
  }
  .footer__content .footer__column--social {
    grid-area: social;
  }
}

@media screen and (min-width: 900px) and (max-width: 1279px) {
  .footer__content {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "copyright copyright copyright" "address contact social";
  }
  .footer__content .footer__column--copyright {
    grid-area: copyright;
  }
  .footer__content .footer__column--address {
    grid-area: address;
  }
  .footer__content .footer__column--contact {
    grid-area: contact;
  }
  .footer__content .footer__column--social {
    grid-area: social;
  }
}

@media screen and (min-width: 1280px) {
  .footer__content {
    grid-template-columns: 300px 1fr 1fr 1fr;
    grid-gap: 80px;
    gap: 80px;
  }
}

.footer__content a,
.footer__content a:link,
.footer__content a:visited {
  color: inherit;
}

.footer__heading {
  color: white;
}

.footer p {
  margin: 0;
}

.footer .footer__copyright {
  margin: .1rem 0;
}

.footer .footer__iso {
  margin: 2rem 0 0;
}

.footer__buttons {
  text-align: center;
  display: grid;
  grid-gap: 25px;
  gap: 25px;
}

@media screen and (min-width: 650px) {
  .footer__buttons {
    display: flex;
    justify-content: center;
  }
  .footer__buttons .button {
    max-width: 240px;
    margin: 13px;
  }
}

.footer__buttons .button,
.footer__buttons .button:link,
.footer__buttons .button:visited {
  transition: background-color 0.2s ease-out;
}

.footer__buttons .button:hover,
.footer__buttons .button:focus,
.footer__buttons .button:active {
  background-color: #00569d;
}

.footer__links {
  margin: 25px;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__link-item {
  margin: 0 10px;
  /* img {
			height: 44px;
			width: auto;
		} */
}

.footer__link,
.footer__link:link,
.footer__link:visited {
  text-decoration: none;
  transform: translateY(0);
  transition: transform 0.2s ease-out;
}

.footer__link:hover,
.footer__link:focus,
.footer__link:active {
  transform: translateY(-15px);
}

/*
* DESIGN COMPONENTS
*/
.fullscreen-search {
  transition: background-color 0.1s ease-out;
  position: fixed;
  top: 0;
  left: -999em;
  z-index: 19000;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  text-align: center;
  background: rgba(25, 43, 60, 0.9);
}

.fullscreen-search:target,
.fullscreen-search[data-is-shown="true"] {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  -webkit-backdrop-filter: blur(8px) grayscale(100%);
  backdrop-filter: blur(8px) grayscale(100%);
  display: flex;
  align-items: center;
}

.fullscreen-search__form {
  font-size: 1rem;
  max-width: 1300px;
  padding: 0 5%;
  width: 100%;
  text-align: left;
  color: #fff;
  line-height: 1.4;
  margin: -48px auto 0;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .fullscreen-search__form {
    margin-top: -72px;
  }
}

.fullscreen-search__close {
  transition: background-color 0.15s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23fff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E") no-repeat 50% 50%;
}

@media screen and (min-width: 800px) {
  .fullscreen-search__close {
    width: 72px;
    height: 72px;
  }
}

.fullscreen-search__close,
.fullscreen-search__close:link,
.fullscreen-search__close:visited {
  text-decoration: none;
}

.fullscreen-search__close:active,
.fullscreen-search__close:focus,
.fullscreen-search__close:hover {
  background-color: #395697;
}

.fullscreen-search__label {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 0.9em;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.fullscreen-search__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background: transparent;
  color: #fff;
  width: 48px;
  height: 48px;
  margin-left: 25px;
}

@media screen and (min-width: 800px) {
  .fullscreen-search__button {
    width: 72px;
    height: 72px;
  }
}

.fullscreen-search__input {
  box-sizing: border-box;
  font-size: 1.5rem;
  width: 100%;
  background: transparent;
  color: #fff;
  outline: 0;
  font-weight: 700;
  padding: 1rem 1rem 1rem 0;
  border: dotted rgba(255, 255, 255, 0.8);
  border-width: 0 0 2px;
}

@media screen and (min-width: 580px) {
  .fullscreen-search__input {
    font-size: 1.875rem;
  }
}

@media screen and (min-width: 800px) {
  .fullscreen-search__input {
    font-size: 2.375rem;
  }
}

.fullscreen-search__input:focus {
  box-shadow: none !important;
}

.fullscreen-search__input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.fullscreen-search__input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.fullscreen-search__input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.fullscreen-search__input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.fullscreen-search__input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 32px;
  height: 21px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 32px;
  height: 3px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -9px;
}

.hamburger-inner::after {
  bottom: -9px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 64px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 64px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 64px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 64px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 64px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 64px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-6.4px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-6.4px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(6.4px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(6.4px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-6.4px, -8px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-6.4px, 8px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(6.4px, -8px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(6.4px, 8px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -18px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -18px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 9px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 18px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 9px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 18px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 9px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -64px;
  top: -64px;
  transform: translate3d(64px, 64px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -64px;
  top: -64px;
  transform: translate3d(-64px, 64px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 9px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -64px;
  top: 64px;
  transform: translate3d(64px, -64px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -64px;
  top: 64px;
  transform: translate3d(-64px, -64px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1.5px;
}

.hamburger--slider .hamburger-inner::before {
  top: 9px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 18px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-4.57143px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 9px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 18px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(4.57143px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 9px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 18px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 9px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -18px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.carousel {
  background-color: #00122d;
  color: white;
  position: relative;
}

.carousel__list {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-x: auto;
  position: relative;
}

.carousel__item {
  width: 100%;
  flex: 1 0 100%;
  position: relative;
  overflow: hidden;
  left: -100%;
  padding: 10rem 0;
  min-height: 700px;
  background: transparent none no-repeat 50% 100% / cover;
}

.carousel__item:only-child {
  left: 0;
}

.carousel__item img {
  width: 100%;
  height: auto;
}

.carousel.products-carousel .carousel__item {
  padding: 100px 0;
  min-height: auto;
}

.carousel__content {
  max-width: 40rem;
}

.carousel__heading {
  font-size: 2.625rem;
  font-weight: bold;
  font-family: "Titillium Web", sans-serif;
  line-height: 1;
  margin: 0;
}

@media screen and (min-width: 1280px) {
  .carousel__heading {
    font-size: 4.375rem;
  }
}

@media screen and (min-width: 1280px) {
  .carousel.products-carousel .carousel__heading {
    font-size: 3.75rem;
  }
}

.carousel__subheading {
  font-size: 1.375rem;
  font-weight: 300;
  font-family: "Titillium Web", sans-serif;
  line-height: 1;
  margin: 0;
}

@media screen and (min-width: 1280px) {
  .carousel__subheading {
    font-size: 2.25rem;
  }
}

.carousel__text {
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.64706;
  margin: 2rem 0;
}

@media screen and (min-width: 1280px) {
  .carousel__text {
    font-size: 1.0625rem;
  }
}

.carousel__arrows {
  position: absolute;
  left: 5%;
  top: 90px;
}

.carousel__nav {
  text-align: center;
  padding: 0 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
}

.carousel__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  outline: 0;
  border-radius: 50%;
  background-color: transparent;
  background-image: none;
  padding: 0;
  color: white;
  width: 45px;
  height: 45px;
  border: 2px white solid;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-out;
  opacity: 0.2;
  cursor: pointer;
}

.carousel__button svg {
  fill: currentColor;
}

.carousel__button:hover, .carousel__button:focus {
  opacity: 1;
}

.carousel__button:not(:first-child) {
  margin-left: 15px;
}

.carousel__indicator {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 17px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 8px rgba(255, 255, 255, 0.3) solid;
  border-radius: 50%;
  cursor: pointer;
}

.carousel__indicator--current {
  background-color: transparent;
  border: 4px white solid;
}

.carousel.products-carousel .carousel__arrows {
  display: none !important;
}

.button {
  border-radius: 3px;
  padding: 1.15rem 3rem;
  font-size: 1rem;
  margin: 0;
  min-width: 240px;
  background-color: #00569d;
  color: white;
  text-align: center;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button, .button:link, .button:visited {
  color: white;
  text-decoration: none;
}

.button--footer-button {
  background-color: #0d2433;
}

.button--forward::after {
  content: url("data:image/svg+xml;utf8,%3Csvg width='14' height='9' viewBox='0 0 14 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M10.086,3.481l-10.086,-0l0,2l10.125,-0l-1.832,1.831l1.414,1.415l4.233,-4.246l-4.233,-4.208l-1.414,1.415l1.793,1.793Z' style='fill:%23fff;'/%3E%3C/svg%3E");
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  display: inline-block;
  margin-left: 12px;
}

.button--forward:hover::after, .button--forward:focus::after {
  transform: translateX(0.5rem);
}

.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}

@media screen and (max-width: 799px) {
  .main-nav {
    background-color: white;
    position: fixed;
    z-index: 200;
    left: 0;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    top: 0;
    bottom: 0;
    padding-top: 8rem;
  }
  .menu-is-open .main-nav {
    transform: translateX(0);
  }
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .main-nav {
    order: 99;
    width: 100%;
  }
}

.main-nav__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media screen and (min-width: 600px) {
  .main-nav__list {
    flex-direction: row;
  }
}

.main-nav__item {
  display: inline-block;
  margin: 0 25px;
  padding: 8px 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 1.125rem;
  position: relative;
}

@media screen and (min-width: 800px) {
  .main-nav__item {
    padding: 26px 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .main-nav__item {
    padding-top: 0;
  }
}

.main-nav__link::after  {
  content: "";
  opacity: 0;
  transform: translate(-50%, 0px);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  position: absolute;
  bottom: 4px;
  left: 50%;
  width: 18px;
  height: 7px;
  background: url("data:image/svg+xml;utf8,%3Csvg width='18' height='7' viewBox='0 0 18 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M17.636,0.416l-0.711,0.416c-1.474,0.867 -2.705,1.301 -3.676,1.301c-0.503,-0 -1.648,-0.295 -3.451,-0.902c-0.156,-0.034 -0.33,-0.104 -0.503,-0.156c-1.509,-0.52 -2.688,-0.85 -3.503,-0.988c-0.312,-0.052 -0.59,-0.087 -0.798,-0.087c-1.612,0 -3.277,0.728 -4.994,2.168l0.416,3.676l0.711,-0.416c0.468,-0.312 1.058,-0.59 1.769,-0.885c0.017,0 0.017,-0.017 0.035,-0.017c0.745,-0.295 1.37,-0.434 1.907,-0.434c0.399,0 1.301,0.209 2.688,0.642c0.399,0.121 0.85,0.26 1.335,0.416c2.151,0.711 3.607,1.058 4.37,1.058c0.763,0 1.63,-0.225 2.584,-0.694c0.954,-0.485 1.682,-0.971 2.185,-1.456l-0.364,-3.642Z' style='fill:%2300569d;fill-rule:nonzero;'/%3E%3C/svg%3E");
}

.main-nav__link, .main-nav__link:link, .main-nav__link:visited {
  text-decoration: none;
  color: #182b3c;
}

.main-nav__link:hover, .main-nav__link:focus, .main-nav__link:active {
  color: #00569d;
}

.main-nav__link:hover::after, .main-nav__link:focus::after, .main-nav__link:active::after {
  opacity: 1;
  transform: translate(-50%, -100%);
}

.main-nav__item--active a,
.main-nav__item--active a:link,
.main-nav__item--active a:visited,
.main-nav__item--active a:hover,
.main-nav__item--active a:focus,
.main-nav__item--active a:active {
  color: #00569d;
}

.main-nav__item--active a::after,
.main-nav__item--active a:link::after,
.main-nav__item--active a:visited::after,
.main-nav__item--active a:hover::after,
.main-nav__item--active a:focus::after,
.main-nav__item--active a:active::after {
  opacity: 1;
  transform: translate(-50%, -100%);
}

@keyframes swipeview-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes swipeview-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.swipeview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30999;
  background: rgba(56, 52, 51, 0.85);
  backdrop-filter: blur(5px);
  scroll-behavior: auto;
  opacity: 0;
}

.swipeview:not([data-is-open]) {
  visibility: hidden;
}

.swipeview[data-is-open="true"] {
  display: block;
  animation: swipeview-reveal 0.25s ease-out forwards;
}

.swipeview[data-is-open="false"] {
  animation: swipeview-hide 0.25s ease-out forwards;
}

.swipeview__list-wrapper {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  scroll-behavior: auto;
}

.swipeview__list {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  --edge-transition: none;
  --edge-transform: translateX(0%);
}

.swipeview__item {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: var(--edge-transition);
  transform: var(--edge-transform);
}

.swipeview__image {
  user-select: none;
  pointer-events: none;
  object-fit: contain;
  max-height: 100%;
}

.swipeview__button {
  position: fixed;
  box-shadow: none;
  outline: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 0;
  transition: background-color 0.15s ease-in;
  background-color: rgba(56, 52, 51, 0.75);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
  background-size: 65%;
}

.swipeview__button:hover, .swipeview__button:focus {
  outline: 0;
  background-color: #00569d;
}

@media screen and (min-width: 800px) {
  .swipeview__button {
    width: 70px;
    height: 70px;
  }
}

.swipeview__button[disabled] {
  visibility: hidden;
}

.swipeview__button.-exit-button {
  right: 0;
  top: 0;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}

.swipeview__button.-previous-button, .swipeview__button.-next-button {
  transform: translateY(-50%);
}

.swipeview__button.-previous-button {
  left: 0;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M8.502 20c-.003-.259.092-.519.289-.716l19.008-19.007c.389-.389 1.02-.389 1.408 0s.389 1.019 0 1.407l-18.315 18.316 18.315 18.315c.389.389.389 1.019 0 1.407s-1.02.389-1.408 0l-19.008-19.006c-.197-.197-.292-.457-.289-.716z'/%3E%3C/svg%3E");
}

.swipeview__button.-next-button {
  right: 0;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M31.209 20.716l-19.008 19.007c-.389.389-1.019.389-1.407 0s-.389-1.019 0-1.407l18.314-18.316-18.315-18.315c-.389-.389-.389-1.019 0-1.407s1.019-.389 1.407 0l19.008 19.007c.197.197.292.457.289.716.004.258-.091.518-.288.715z'/%3E%3C/svg%3E");
}

.pagination {
  display: inline-flex;
  margin: initial auto;
}

.pagination__list {
  padding: 0;
  margin: 1em 0;
  list-style-type: none;
  text-align: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 25px;
  gap: 25px;
}

@media screen and (min-width: 800px) {
  .gallery {
    grid-gap: 35px;
    gap: 35px;
  }
}

@media screen and (min-width: 1024px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    grid-gap: 45px;
    gap: 45px;
  }
}

@media screen and (min-width: 1200px) {
  .gallery {
    grid-gap: 60px;
    gap: 60px;
  }
}

.gallery__item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.gallery__link {
  line-height: 0;
  display: block;
  position: relative;
}

.gallery__link::after  {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(13, 36, 51, 0.4) url("/assets/images/magnifying-glass.svg") no-repeat center center;
  opacity: 0;
  box-shadow: 0 0 0 0 white inset;
  transition: opacity 0.2s ease-out, box-shadow 0.2s ease-out;
  mix-blend-mode: hard-light;
}

.gallery__link:hover::after , .gallery__link:focus::after , .gallery__link:active::after  {
  box-shadow: 0 0 0 6px white inset;
  opacity: 1;
}

.gallery__image {
  width: 100%;
}
