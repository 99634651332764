.main-nav {
	@include max(799px) {
		background-color: white;
		position: fixed;
		z-index: 200;
		left: 0;
		width: 100%;
		transform: translateX(-100%);
		transition: transform 0.3s ease-out;
		top: 0;
		bottom: 0;
		padding-top: 8rem;

		.menu-is-open & {
			transform: translateX(0);
		}
	}

	@include minmax(800px, 1100px) {
		order: 99;
		width: 100%;
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		list-style-type: none;

		@include min(600px) {
			flex-direction: row;
		}
	}

	&__item {
		display: inline-block;
		margin: 0 25px;
		padding: 8px 0 20px;
		text-align: center;
		font-weight: bold;
		font-size: rem(18px);
		position: relative;

		@include min(800px) {
			padding: 26px 0;
		}

		@include minmax(800px, 1100px) {
			padding-top: 0;
		}
	}

	&__link {
		&::after  {
			content: "";
			opacity: 0;
			transform: translate(-50%, 0px);
			transition: transform 0.2s ease-out, opacity 0.2s ease-out;
			position: absolute;
			bottom: 4px;
			left: 50%;
			width: 18px;
			height: 7px;
			background: encode-svg(
				'<svg width="18" height="7" viewBox="0 0 18 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M17.636,0.416l-0.711,0.416c-1.474,0.867 -2.705,1.301 -3.676,1.301c-0.503,-0 -1.648,-0.295 -3.451,-0.902c-0.156,-0.034 -0.33,-0.104 -0.503,-0.156c-1.509,-0.52 -2.688,-0.85 -3.503,-0.988c-0.312,-0.052 -0.59,-0.087 -0.798,-0.087c-1.612,0 -3.277,0.728 -4.994,2.168l0.416,3.676l0.711,-0.416c0.468,-0.312 1.058,-0.59 1.769,-0.885c0.017,0 0.017,-0.017 0.035,-0.017c0.745,-0.295 1.37,-0.434 1.907,-0.434c0.399,0 1.301,0.209 2.688,0.642c0.399,0.121 0.85,0.26 1.335,0.416c2.151,0.711 3.607,1.058 4.37,1.058c0.763,0 1.63,-0.225 2.584,-0.694c0.954,-0.485 1.682,-0.971 2.185,-1.456l-0.364,-3.642Z" style="fill:#00569d;fill-rule:nonzero;"/></svg>'
			);
		}

		@include link(&) {
			text-decoration: none;
			color: #182b3c;
		}

		@include link-over(&) {
			color: $color-brand;

			&::after {
				opacity: 1;
				transform: translate(-50%, -100%);
			}
		}
	}

	&__item--active {
		@include link-all() {
			color: $color-brand;

			&::after {
				opacity: 1;
				transform: translate(-50%, -100%);
			}
		}
	}
}
