.fullscreen-search {
	transition: background-color 0.1s ease-out;
	position: fixed;
	top: 0;
	left: -999em;
	z-index: 19000;
	width: 0.1px;
	height: 0.1px;
	overflow: hidden;
	text-align: center;
	background: rgba($color-brand-dark, 0.9);
}

.fullscreen-search:target,
.fullscreen-search[data-is-shown="true"] {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: auto;
	height: auto;
	-webkit-backdrop-filter: blur(8px) grayscale(100%);
	backdrop-filter: blur(8px) grayscale(100%);
	display: flex;
	align-items: center;
}

.fullscreen-search__form {
	font-size: 1rem;
	max-width: 1300px;
	padding: 0 5%;
	width: 100%;
	text-align: left;
	color: #fff;
	line-height: 1.4;
	margin: -48px auto 0;
	display: flex;
	align-items: center;

	@include min(800px) {
		margin-top: -72px;
	}
}

.fullscreen-search__close {
	transition: background-color 0.15s ease-out;
	position: absolute;
	top: 0;
	right: 0;
	width: 48px;
	height: 48px;
	cursor: pointer;
	background: transparent
		url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23fff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E")
		no-repeat 50% 50%;

	@include min(800px) {
		width: 72px;
		height: 72px;
	}
}

.fullscreen-search__close,
.fullscreen-search__close:link,
.fullscreen-search__close:visited {
	text-decoration: none;
}

.fullscreen-search__close:active,
.fullscreen-search__close:focus,
.fullscreen-search__close:hover {
	background-color: #395697;
}

.fullscreen-search__label {
	font-size: 1.125rem;
	display: block;
	margin: 0 0 0.9em;
	color: #fff;
	text-transform: uppercase;
	font-weight: 600;
}

.fullscreen-search__button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 0;
	background: transparent;
	color: #fff;
	width: 48px;
	height: 48px;
	margin-left: 25px;

	@include min(800px) {
		width: 72px;
		height: 72px;
	}
}

.fullscreen-search__input {
	box-sizing: border-box;
	font-size: rem(24px);
	width: 100%;
	background: transparent;
	color: #fff;
	outline: 0;
	font-weight: 700;
	padding: 1rem 1rem 1rem 0;
	border: dotted hsla(0, 0%, 100%, 0.8);
	border-width: 0 0 2px;

	@include min(580px) {
		font-size: rem(30px);
	}

	@include min(800px) {
		font-size: rem(38px);
	}
}

.fullscreen-search__input:focus {
	box-shadow: none !important;
}

.fullscreen-search__input::-webkit-input-placeholder {
	color: hsla(0, 0%, 100%, 0.8);
}

.fullscreen-search__input::-moz-placeholder {
	color: hsla(0, 0%, 100%, 0.8);
}

.fullscreen-search__input:-ms-input-placeholder {
	color: hsla(0, 0%, 100%, 0.8);
}

.fullscreen-search__input::-ms-input-placeholder {
	color: hsla(0, 0%, 100%, 0.8);
}

.fullscreen-search__input::placeholder {
	color: hsla(0, 0%, 100%, 0.8);
}
