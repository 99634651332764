::selection {
	background: $color-brand;
	color: $color-light;
}

@include link {
	color: inherit;
	text-decoration: underline;
}

@include link-over {
	//color: $color-brand;
}

@include link-visited("p a") {
	opacity: 0.8;
}

@include headings(1, 6) {
	font-family: $font-headings;

	&:not(:first-child) {
		margin-top: 0;
	}
}

@include headings(1, 4) {
	font-weight: 700;
	line-height: 1.15;
	margin: 0 0 0.75em 0;
}

@include headings(5, 6) {
	font-weight: 600;
	margin: 0 0 0.5em 0;
}

.main-heading {
	font-size: rem(34px);
	font-weight: bold;

	@include min(800px) {
		font-size: rem(40px);
	}

	@include min(1200px) {
		font-size: rem(46px);
	}
}

h2 {
	font-size: rem(44px);

	@include min(800px) {
		font-size: rem(50px);
	}

	@include min(1280px) {
		font-size: rem(60px);
	}
}

h3 {
	font-size: 1.875rem;
	font-weight: normal;
}

h4 {
	font-size: 1.625rem;
}

h5 {
	font-size: 1.375rem;
}

h6 {
	font-size: 1.125rem;
}

:focus {
	outline: 0;
}

.keyboard-focus:focus {
	@extend %default-outline;
}

blockquote {
	padding: 4vw;
	margin: 5vw;
	border-left: 1em $color-brand solid;
	font-size: 1.6em;
	font-style: normal;

	/* cite {
		font-style: normal;
	} */

	p {
		margin: 0;
		position: relative;
	}

	@include min(800px) {
		margin: 4vw;
	}

	@include min(1200px) {
		margin: 3em 5em;
		padding-left: 4em;
		padding-right: 4em;
	}
}

code,
pre {
	font-size: 1em;
	font-family: $font-monospace;
}

.vh {
	position: absolute;
	left: -9999rem;
	width: 0;
	height: 0;
}
