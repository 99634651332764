.header {
	background: white;
	color: $color-base;
	position: sticky;
	top: 0;
	z-index: 100;

	@include min(800px) {
		position: relative;
	}

	&__content  {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	&__logo {
		margin: 18px 0;
		line-height: 0;

		@include max(799px) {
			position: relative;
			z-index: 220;
		}

		@include min(800px) {
			margin: 28px 0;
		}
	}

	&__logo-image {
		width: 200px;

		@include min(800px) {
			width: 300px;
		}
	}

	&__menu-button {
		position: absolute;
		top: 50%;
		right: calc(5% - 15px);
		z-index: 300;
		transform: translateY(calc(-50% - 0.15em));

		@include min(800px) {
			display: none;
		}
	}

	&__tools {
		font-size: rem(14px);

		@include max(799px) {
			position: fixed;
			z-index: 205;
			left: 0;
			top: 5rem;
			width: 100%;
			transform: translateX(-100%);
			transition: transform 0.3s ease-out;
			padding: 0 5%;

			.menu-is-open & {
				transform: translateX(0);
			}
		}
	}

	&__tools-list {
		display: flex;
		align-items: center;
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-weight: bold;

		@include max(799px) {
			justify-content: center;
		}
	}

	&__tools-item {
		&:not(:first-child) {
			margin-left: 50px;
		}
	}

	@include link(".header__tools-link") {
		text-decoration: none;
		color: #6d6d6d;

		svg {
			fill: currentColor;
		}
	}

	@include link-over(".header__tools-link") {
		color: $color-brand;
	}

	&__tools-link {
		display: inline-flex;
		justify-content: center;
		align-items: center;

		svg {
			fill: currentColor;
		}
	}

	&__tools-icon {
		margin-left: 14px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
}
