.flair {
	height: 0;
	padding-top: 120px;
	background: $color-footer url(/assets/images/footer-bg.jpg) no-repeat center
		center / cover;
}

.footer {
	text-align: left;
	margin-top: auto;
	color: #638094;
	background: $color-footer url(/assets/images/pattern-dark.svg) repeat-x left -6px
		bottom 6px;
	padding: 50px 0;

	@include min(800px) {
		padding: 80px 0;
	}

	@include min(1280px) {
		padding: 120px 0;
	}

	&__content {
		text-align: center;
		display: grid;
		margin-bottom: 42px;
		font-size: rem(14px);
		line-height: (23 / 14);
		grid-gap: 40px;
		gap: 40px;

		@include minmax(600px, 899px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				"copyright copyright"
				"address contact"
				"social social";

			.footer__column--copyright {
				grid-area: copyright;
			}
			.footer__column--address {
				grid-area: address;
			}
			.footer__column--contact {
				grid-area: contact;
			}
			.footer__column--social {
				grid-area: social;
			}
		}

		@include minmax(900px, 1279px) {
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas:
				"copyright copyright copyright"
				"address contact social";

			.footer__column--copyright {
				grid-area: copyright;
			}
			.footer__column--address {
				grid-area: address;
			}
			.footer__column--contact {
				grid-area: contact;
			}
			.footer__column--social {
				grid-area: social;
			}
		}

		@include min(1280px) {
			grid-template-columns: 300px 1fr 1fr 1fr;
			grid-gap: 80px;
			gap: 80px;
		}

		@include link {
			color: inherit;
		}
	}

	&__heading {
		color: white;
	}

	p {
		margin: 0;
	}

	.footer__copyright {
		margin: .1rem 0;
	}

	.footer__iso {
		margin: 2rem 0 0;
	}

	&__buttons {
		text-align: center;
		display: grid;
		grid-gap: 25px;
		gap: 25px;

		@include min(650px) {
			display: flex;
			justify-content: center;

			.button {
				max-width: 240px;
				margin: 13px;
			}
		}

		@include link(".button") {
			transition: background-color 0.2s ease-out;
		}

		@include link-over(".button") {
			background-color: $color-brand;
		}
	}

	&__links {
		margin: 25px;
		padding: 0;
		list-style-type: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__link-item {
		margin: 0 10px;

		/* img {
			height: 44px;
			width: auto;
		} */
	}
}

@include link(".footer__link") {
	text-decoration: none;
	transform: translateY(0);
	transition: transform 0.2s ease-out;
}

@include link-over(".footer__link") {
	transform: translateY(-15px);
}
