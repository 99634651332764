.carousel {
	background-color: #00122d;
	color: white;
	position: relative;

	&__list {
		display: flex;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style-type: none;
		overflow-x: auto;
		position: relative;
	}

	&__item {
		width: 100%;
		flex: 1 0 100%;
		position: relative;
		overflow: hidden;
		left: -100%;
		padding: 10rem 0;
		min-height: 700px;
		background: transparent none no-repeat 50% 100% / cover;

		&:only-child {
			left: 0;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&.products-carousel .carousel__item {
		padding: 100px 0;
		min-height: auto;
	}

	&__content {
		max-width: 40rem;
	}

	&__heading {
		font-size: rem(42px);
		font-weight: bold;
		font-family: $font-headings;
		line-height: 1;
		margin: 0;

		@include min(1280px) {
			font-size: rem(70px);
		}
	}

	&.products-carousel .carousel__heading {
		@include min(1280px) {
			font-size: rem(60px);
		}
	}

	&__subheading {
		font-size: rem(22px);
		font-weight: 300;
		font-family: $font-headings;
		line-height: 1;
		margin: 0;

		@include min(1280px) {
			font-size: rem(36px);
		}
	}

	&__text {
		font-size: rem(15px);
		font-weight: 300;
		line-height: (28 / 17);
		margin: 2rem 0;

		@include min(1280px) {
			font-size: rem(17px);
		}
	}

	&__arrows {
		position: absolute;
		left: 5%;
		top: 90px;
	}

	&__nav {
		text-align: center;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 50px;
	}

	&__button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		box-shadow: none;
		border: 0;
		outline: 0;
		border-radius: 50%;
		background-color: transparent;
		background-image: none;
		padding: 0;
		color: white;
		width: 45px;
		height: 45px;
		border: 2px white solid;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: opacity 0.2s ease-out;
		opacity: 0.2;
		cursor: pointer;

		svg {
			fill: currentColor;
		}

		&:hover,
		&:focus {
			opacity: 1;
		}

		&:not(:first-child) {
			margin-left: 15px;
		}
	}

	&__indicator {
		display: inline-block;
		width: 16px;
		height: 16px;
		margin: 0 17px;
		background-color: rgba(white, .3);
		border: 8px rgba(white, .3) solid;
		border-radius: 50%;
		cursor: pointer;

		&--current {
			background-color: transparent;
			border: 4px white solid;
		}
	}

	&.products-carousel {
		.carousel__arrows {
			display: none !important
		}
	}
}
